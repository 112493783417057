import { injectable, inject } from "inversify"
import { TYPES } from "./types"
import type { IApiService } from "./apiService"
import type { IConfigService } from "./configService"
import type { IForm } from "domains/interfaces/form"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type {
  IHotlineChatMessage,
  IHotlineChatRoom,
  IHotLineChatRoomResponse,
  IHotlineListResponse,
  IHotlinePage,
  IHotlineRequestFormAnswer,
  IHotlineRequestFormResponse,
} from "domains/interfaces/hotline"
// import Echo from "laravel-echo"
import Pusher from "pusher-js"
import type { ITokenService } from "./tokenService"

Pusher.logToConsole = false
window.csrfToken = "{{ csrf_token() }}"
window.Pusher = require("pusher-js")
// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.REACT_APP_PUSHER_APP_KEY ?? "",
//   cluster: "ap1",
//   logToConsole: false,
//   authEndpoint: process.env.REACT_APP_ECHO_APP_AUTH_ENDPOINT ?? "",
//   forceTLS: false,
//   disableStats: true,
//   wsHost: window.location.hostname,
//   wsPort: 6001,
//   auth: {
//     headers: {
//       "Content-Type": "application/json",
//       "X-CSRF-TOKEN": "{{ csrf_token() }}",
//     },
//   },
// })

export interface IHotlineService {
  closeChatRoom(chatRoomId: number): Promise<IHotlineChatRoom>
  getChatRoom(chatRoomId: number): Promise<IHotlineChatRoom | null>
  // getChatRoomChannel(chatRoomId: number): any
  getChatRoomRequestForm(): Promise<{ form: IForm }>
  getMessages(chatRoomId: number): Promise<Array<IHotlineChatMessage>>
  listMyChatRooms(filter?: any): Promise<IHotlinePage>
  requestChatRoom(answer: IHotlineRequestFormAnswer): Promise<any>
  sendMessage(chatRoomId: number, message: string): Promise<IHotlineChatMessage>
}

@injectable()
export class HotlineService implements IHotlineService {
  private apiService: IApiService
  private configService: IConfigService
  private tokenService: ITokenService
  // private echo?: Echo
  private pusher?: Pusher

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.IConfigService) configService: IConfigService,
    @inject(TYPES.ITokenService) tokenService: ITokenService
  ) {
    this.apiService = apiService
    this.configService = configService
    this.tokenService = tokenService
  }

  private initEcho() {
    this.pusher = require("pusher-js")
    // this.echo = new Echo({
    //   broadcaster: "pusher",
    //   key: this.configService.pusherAppKey,
    //   cluster: this.configService.pusherAppCluster,
    //   forceTLS: true,
    //   authEndpoint: this.configService.echoAppAuthEndpoint,
    //   auth: {
    //     headers: {
    //       Authorization: `Bearer ${this.tokenService.accessToken}`,
    //       "Content-Type": "application/json",
    //     },
    //   },
    // })
  }

  public async closeChatRoom(chatRoomId: number): Promise<IHotlineChatRoom> {
    const response = await this.apiService.get<IBaseResponse<{ chat_room: IHotlineChatRoom }>>(
      `hotline/chat-rooms/${chatRoomId}/close`
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.chat_room
  }

  public async getChatRoom(chatRoomId: number): Promise<IHotlineChatRoom | null> {
    const response = await this.apiService.get<IHotLineChatRoomResponse>(
      `hotline/chat-rooms/${chatRoomId}`
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      return null
    }
    return response.data.data.chat_room
  }

  // public getChatRoomChannel(chatRoomId: number): any {
  //   if (!this.echo) {
  //     this.initEcho()
  //   }
  //   return this.echo?.join(`hotline-chat-room.${chatRoomId}`)
  // }

  public async getChatRoomRequestForm(): Promise<{ form: IForm }> {
    const response = await this.apiService.get<IHotlineRequestFormResponse>(
      `hotline/chat-rooms/request/form`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    let formatData: any = response.data.data
    return formatData!
  }

  public async getMessages(chatRoomId: number): Promise<Array<IHotlineChatMessage>> {
    const response = await this.apiService.get<
      IBaseResponse<{ chat_messages: IHotlineChatMessage[] }>
    >(`hotline/chat-rooms/${chatRoomId}/messages`, {}, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      return []
    }
    return response.data.data.chat_messages
  }

  public async listMyChatRooms(filter: any = { page: 1, size: 10 }): Promise<IHotlinePage> {
    const response = await this.apiService.get<IHotlineListResponse>(
      `hotline/chat-rooms`,
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async requestChatRoom(answer: IHotlineRequestFormAnswer): Promise<any> {
    const response = await this.apiService.post<IBaseResponse<{ chat_room: any }>>(
      "hotline/chat-rooms/request",
      answer,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.chat_room
  }

  public async sendMessage(chatRoomId: number, message: string): Promise<IHotlineChatMessage> {
    const data = { message }
    const response = await this.apiService.post<
      IBaseResponse<{ chat_message: IHotlineChatMessage }>
    >(`hotline/chat-rooms/${chatRoomId}/messages`, data)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.chat_message
  }
}
