import type { Container } from "inversify"
import { TYPES } from "services/types"
import { observable, action } from "mobx"
import type { IRegisterRequestBody } from "domains/interfaces/registration"
import type { IRegistrationService } from "services/registrationService"
import type { Navigation } from "navi"

export class RegisterViewModel {
  private registrationService: IRegistrationService
  private navigation: Navigation<any>

  @observable
  public errorMessage = ""

  constructor(container: Container, navigation: Navigation<any>) {
    this.registrationService = container.get<IRegistrationService>(TYPES.IRegistrationService)
    this.navigation = navigation
  }

  @action.bound
  public async onSubmitForm(values: any) {
    const { email, username, password, firstname, lastname, sex } = values
    const registrationBody: IRegisterRequestBody = {
      firstname,
      lastname,
      username,
      email,
      password,
      sex,
    }
    try {
      await this.registrationService.register(registrationBody)
      this.navigation.navigate("login")
    } catch (error) {
      this.errorMessage = (error as Error).message
    }
  }

  @action.bound
  public async isUsernameAvailable(username: string): Promise<boolean> {
    return this.registrationService.isUsernameAvailable(username)
  }

  @action.bound
  public validateUsernameAlphaNum(username: string): boolean {
    if (username.match(/^[0-9a-zA-Z_]+$/)) {
      return true
    }
    return false
  }
}
