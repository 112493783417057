import { inject, injectable } from "inversify"
import { action, observable } from "mobx"
import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type { IUser, IUserPrivacyItem, IUserResponse } from "domains/interfaces/user"
import { CachableResource } from "domains/models/cachableResource"
import type { IApiService } from "./apiService"
import type { IProfileService } from "./profileService"
import { TYPES } from "./types"
import { ResourceType, WidgetType } from "domains/types/mindspaceplus"

export interface IUserService {
  readonly userObservable: IUser | undefined
  readonly user: Promise<IUser>
  readonly isAdmin: Promise<boolean>
  readonly isCustomer: Promise<boolean>
  readonly isCounselor: Promise<boolean>
  readonly isPsychologist: Promise<boolean>
  readonly isPsychiatrist: Promise<boolean>
  readonly profileImage: Promise<string>
  acceptAgreement(accept: boolean): Promise<void>
  getLaunchingFormToMindSpacePlus(resourceId: ResourceType, widgetIds: WidgetType[]): Promise<string>
  getMyPrivacySettingsForMindSpacePlus(): Promise<IUserPrivacyItem[]>
  updateMyPrivacySettingsForMindSpacePlus(privacyItems: IUserPrivacyItem[]): Promise<boolean>
  sendUserToMindSpacePlus(): Promise<void>
}

@injectable()
export class UserService implements IUserService {
  private apiService: IApiService
  private profileService: IProfileService
  private cachedUser: CachableResource<IUser>
  private cachedUserProfilePath: CachableResource<string>

  @observable
  public userObservable: IUser | undefined

  public get user(): Promise<IUser> {
    return this.cachedUser.resource
  }

  public get isAdmin(): Promise<boolean> {
    return (async () => !!(await this.user).admin)()
  }

  public get isCustomer(): Promise<boolean> {
    return (async () => !!(await this.user).customer)()
  }

  public get isCounselor(): Promise<boolean> {
    return (async () => {
      const user = await this.user
      return !!user.counselor
    })()
  }

  public get isPsychologist(): Promise<boolean> {
    return (async () => {
      const user = await this.user
      return !!user.counselor && user.counselor.position_type === "psychologist"
    })()
  }

  public get isPsychiatrist(): Promise<boolean> {
    return (async () => {
      const user = await this.user
      return !!user.counselor && user.counselor.position_type === "psychiatrist"
    })()
  }

  public get profileImage(): Promise<string> {
    return this.cachedUserProfilePath.resource
  }

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.IProfileService) profileService: IProfileService
  ) {
    this.apiService = apiService
    this.profileService = profileService
    this.cachedUser = new CachableResource<IUser>(async () => this.getMyInfo())
    this.cachedUserProfilePath = new CachableResource<string>(async () => this.getMyProfile(), 1)
  }

  @action.bound
  private async getMyInfo(): Promise<IUser> {
    const response = await this.apiService.get<IUserResponse>("user")
    if (response.status !== ApiResponseStatus.Ok || !response.data.data?.user) {
      console.log("error")
      throw new Error(response.data.message)
    }
    this.userObservable = response.data.data.user
    return response.data.data.user
  }

  private async getMyProfile(): Promise<string> {
    try {
      const response = await this.profileService.getHeaderProfile()
      return response.avatar_image ?? ""
    } catch {
      return ""
    }
  }

  public async acceptAgreement(agree: boolean): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      "users/accept-agreement",
      { agree },
      true
    )
    await this.cachedUser.forceUpdate()
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async getLaunchingFormToMindSpacePlus(resourceId: ResourceType, widgetIds: WidgetType[]): Promise<string> {
    const response = await this.apiService.post<string>(
      "lti/launch/mindspaceplus",
      {
        resource_id: resourceId || 'home',
        widget_ids: widgetIds || [],
      },
      true
    )
    await this.cachedUser.forceUpdate()
    if (response.status !== ApiResponseStatus.Ok) {
      // throw new Error(response.data.message)
      throw new Error("Something seem wrong")
    }
    // // temporary work around
    // window.document.write(response.data)
    return response.data
  }

  public async getMyPrivacySettingsForMindSpacePlus(): Promise<IUserPrivacyItem[]> {
    const response = await this.apiService.get<IBaseResponse<IUserPrivacyItem[]>>(
      "mindspaceplus/my-privacy",
      null,
      true
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data || []
  }

  public async updateMyPrivacySettingsForMindSpacePlus(privacyItems: IUserPrivacyItem[]): Promise<boolean> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      "mindspaceplus/my-privacy",
      { privacy: privacyItems },
      true
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return true
  }

  public async sendUserToMindSpacePlus(): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      "/lti/lunchmyspaceplus",
      {},
      true
    )
    await this.cachedUser.forceUpdate()
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }
}
