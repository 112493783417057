import type { Container } from "inversify"
import { action, computed, observable } from "mobx"
import type { IAuthService } from "services/authService"
import type { IConfigService } from "services/configService"
import type { ILanguageService } from "services/languageService"
import { TYPES } from "services/types"
import type { IUserService } from "services/userService"

export class NavbarViewModel {
  private authService: IAuthService
  private userService: IUserService
  private languageService: ILanguageService
  private configService: IConfigService

  constructor(container: Container) {
    this.authService = container.get<IAuthService>(TYPES.IAuthService)
    this.userService = container.get<IUserService>(TYPES.IUserService)
    this.languageService = container.get<ILanguageService>(TYPES.ILanguageService)
    this.configService = container.get<IConfigService>(TYPES.IConfigService)
  }

  @observable
  public menuOpen: boolean = false

  @observable
  public profileImage: string = ""

  @computed
  public get language(): string {
    return this.languageService.language.toUpperCase()
  }

  @computed
  public get isLoggedIn(): boolean {
    return this.authService.isLoggedIn
  }

  @computed
  public get isAppointmentEnabled(): boolean {
    return this.configService.appointmentEnable
  }


  @action.bound
  public async sendToMindSpacePlus(): Promise<void> {
    const launchingForm = await this.userService.getLaunchingFormToMindSpacePlus('home', [])
    // temporary work around
    window.document.write(launchingForm)
  }

  async init() {
    this.profileImage = await this.userService.profileImage
  }

  @action.bound
  public toggleMenuOpen() {
    this.menuOpen = !this.menuOpen
  }
}
