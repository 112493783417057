import { injectable, inject } from "inversify"
import moment from "moment"
import "moment/locale/th"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"
import type {
  IWorkshop,
  IWorkshopFilter,
  IRegister,
  IRegisterFilter,
  IBlacklist,
  IListRegisterResponse,
  IWorkshopResponse,
  IResultWorkshopResponse,
  IAttendeeDataTopic,
  IFilterAttendeeDataTopic,
  IDeleteMultipleRegisterResponse,
} from "../domains/interfaces/workshop"
import type { IForm, IFormApiResponse } from "domains/interfaces/form"
import type { IConfigService } from "./configService"
import { ApiResponseStatus } from "domains/interfaces/apiResponse"
import type { ILanguageService } from "./languageService"
import type { IFileService } from "./fileService"

export interface IWorkshopService {
  getList(filter: IWorkshopFilter): Promise<any>
  getById(id: number): Promise<any>
  BgetQuestionList(id: number, isExport?: boolean): Promise<Array<IAttendeeDataTopic>>
  BgetList(filter?: IWorkshopFilter): Promise<any>
  BgetById(id: number, preview?: boolean): Promise<IWorkshop | null>
  createWorkshop(workshop: IWorkshop | {}): Promise<IWorkshop>
  cancelWorkshop(id: number): Promise<void>
  editWorkshop(id: number, workshop: IWorkshop): Promise<IWorkshop>
  deleteWorkshop(id: number): Promise<void>
  getRegisterList(id: number, question?: Array<IAttendeeDataTopic>): Promise<any[]>
  deleteRegister(workshopId: number, attendeeId: number[]): Promise<Array<number>>
  getRequestCancelList(id: number, filter: IRegisterFilter): Promise<IRegister[]>
  deleteRequestCancel(id: number): Promise<void>
  addToBlackList(workshopId: number, attendeeId: number, reason: string): Promise<IBlacklist>
  getWorkshopApplicationForm(id: number): Promise<any>
  getWorkshopApplicationFormType(): Promise<any>
  applyWorkshop(workshopId: number, form: any): Promise<void>
  confirmWorkshop(workshopId: number, idList?: Array<number> | null): Promise<void>
  getWorkshopAnnouncement(workshopId: number): Promise<any>
  updateWorkshopAnnouncement(workshopId: number, form: any): Promise<void>
  closeWorkshop(workshopId: number): Promise<void>
  markAttend(workshopId: number, attendeeId: number): Promise<void>
  exportData(workshopId: number, question: Array<IAttendeeDataTopic>): Promise<any>
  checkUserStatusInWorkshop(workshopId: number): Promise<void>
}

@injectable()
export class WorkshopService implements IWorkshopService {
  private apiService: IApiService
  private configService: IConfigService
  private fileService: IFileService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.ILanguageService) languageService: ILanguageService,
    @inject(TYPES.IConfigService) configService: IConfigService,
    @inject(TYPES.IFileService) fileService: IFileService
  ) {
    this.apiService = apiService
    this.configService = configService
    this.fileService = fileService
  }

  public getDateText = (startDate: any, endDate: any) => {
    moment.locale("th")
    if (!startDate && !endDate) return ""
    else if (!startDate) return moment(endDate).format("DD/MM/YYYY HH:mm")
    else if (!endDate) return moment(startDate).format("DD/MM/YYYY HH:mm")
    else if (
      new Date(startDate).getFullYear() === new Date(endDate).getFullYear() &&
      new Date(startDate).getMonth() === new Date(endDate).getMonth() &&
      new Date(startDate).getDate() === new Date(endDate).getDate()
    ) {
      return `${moment(startDate).format("DD/MM/YYYY")} ${moment(startDate).format(
        "HH:mm"
      )} - ${moment(endDate).format("HH:mm")}`
    } else {
      return `${moment(startDate).format("DD/MM/YYYY HH:mm")} - ${moment(endDate).format(
        "DD/MM/YYYY HH:mm"
      )}`
    }
  }

  public getUserDateText = (startDate: any, endDate: any) => {
    moment.locale("th")
    const mStartDate = startDate && moment(startDate).add(543, "year")
    const mEndDate = endDate && moment(endDate).add(543, "year")

    if (!startDate && !endDate) return ""
    if (!startDate) return mStartDate.format("DD/MM/YYYY HH:mm")
    if (!endDate) return mEndDate.format("DD/MM/YYYY HH:mm")
    if (
      new Date(startDate).getFullYear() === new Date(endDate).getFullYear() &&
      new Date(startDate).getMonth() === new Date(endDate).getMonth() &&
      new Date(startDate).getDate() === new Date(endDate).getDate()
    ) {
      // Get real date of weeks
      const dayText = mStartDate.subtract(543, "year").format("dddd")

      // Restore date in BE format
      mStartDate.add(543, "year")

      return `${dayText}, ${mStartDate.format("DD MMMM พ.ศ. YYYY,")} ${mStartDate.format(
        "HH:mm"
      )} - ${mEndDate.format("HH:mm")} น.`
    }
    return `${mStartDate.format("DD/MM/YYYY HH:mm")} - ${mEndDate.format("DD/MM/YYYY HH:mm")}`
  }

  public isWorkshopActive = (workshopStartDate: any, workshopEndDate: any) => {
    if (workshopEndDate && moment(workshopEndDate).isAfter()) return true
    else return false
  }

  public isRegisterActive = (registerStartDate: any, registerEndDate: any) => {
    if (
      registerStartDate &&
      moment(registerStartDate).isBefore() &&
      registerEndDate &&
      moment(registerEndDate).isAfter()
    )
      return true
    else return false
  }

  public async getList(filter: IWorkshopFilter): Promise<any> {
    const response = await this.apiService.get<any>("workshops", filter, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data.workshops) {
      return []
    }

    const { total, data } = response.data.data.workshops

    return {
      total: total,
      workshops: data.map((workshop: any) => {
        return {
          id: workshop.id,
          workshop_name: workshop.workshop_name_th,
          cover_image: this.fileService.getFileUrl(workshop.cover_image),
          workshop_date: this.getUserDateText(
            workshop.workshop_start_date,
            workshop.workshop_end_date
          ),
          place: workshop.workshop_type?.name || "",
          workshop_active: this.isWorkshopActive(
            workshop.workshop_start_date,
            workshop.workshop_end_date
          ),
          workshop_type: workshop.application_form_type_id,
          now_attendees: workshop.attendees_count,
          max_attendees: workshop.maximum_number_of_attendee || 0,
          tags: workshop.tags.map((tag: any) => tag.tag),
        }
      }),
    }
  }

  public async getById(id: number): Promise<any> {
    const response = await this.apiService.get<IWorkshopResponse>(`workshops/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { workshop } = response.data.data
    return {
      id: workshop.id,
      attachment: this.fileService.getFileUrl(workshop.attachment),
      cover_image: this.fileService.getFileUrl(workshop.cover_image),
      workshop_name: workshop.workshop_name_th,
      place: workshop.workshop_type?.name || "",
      register_active: this.isRegisterActive(
        workshop.registration_start_date,
        workshop.registration_end_date
      ),
      workshop_type: workshop.application_form_type.desc.split(" ").slice(2).join(" "),
      register_max: workshop.maximum_number_of_attendee || 0,
      status: workshop.status,
      content: workshop.content,
      workshop_date: this.getUserDateText(workshop.workshop_start_date, workshop.workshop_end_date),
      workshop_start_date: workshop.workshop_start_date,
      workshop_end_date: workshop.workshop_end_date,
      registration_start_date: workshop.registration_start_date,
      registration_end_date: workshop.registration_end_date,
      form_type: workshop.application_form_type_id,
      organizer_name: workshop.organizer_name_th,
      organizer_position: workshop.organizer_position_th,
      published_at: workshop.published_at,
      tags: workshop.tags.map((tag: { tag: string }) => tag.tag),
    }
  }

  //backoffice
  public async BgetList(filter?: IWorkshopFilter): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/workshops", filter, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data.workshops) {
      return []
    }

    const { total, data } = response.data.data.workshops

    return {
      total: total,
      workshops: data.map((workshop: any) => {
        return {
          id: workshop.id,
          workshop_name: workshop.workshop_name_th,
          workshop_date: this.getDateText(workshop.workshop_start_date, workshop.workshop_end_date),
          place: workshop.place,
          created_at: moment(workshop.created_at).format("DD/MM/YYYY HH:mm"),
          view_count: workshop.view_count,
          register_count: workshop.attendees_count ? workshop.attendees_count : 0,
          register_max: workshop.maximum_number_of_attendee || 0,
          published: workshop.published,
          workshop_active: this.isWorkshopActive(
            workshop.workshop_start_date,
            workshop.workshop_end_date
          ),
          cover_image: this.fileService.getFileUrl(workshop.cover_image),
        }
      }),
    }
  }

  public async BgetById(id: number, preview?: boolean): Promise<any> {
    if (preview) return await this.getById(id)

    const response = await this.apiService.get<any>(`backoffice/workshops/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      return null
    }
    const { workshop } = response.data.data

    return {
      ...workshop,
      id: workshop.id,
      cover_image: this.fileService.getFileUrl(workshop.cover_image),
      workshop_name: workshop.workshop_name_th,
      content: workshop.content,
      attachment: this.fileService.getFileUrl(workshop.attachment),
      workshop_date: this.getUserDateText(workshop.workshop_start_date, workshop.workshop_end_date),
      workshop_start_date: workshop.workshop_start_date,
      workshop_end_date: workshop.workshop_end_date,
      registration_start_date: workshop.registration_start_date,
      registration_end_date: workshop.registration_end_date,
      type: workshop.workshop_type_id && Number(workshop.workshop_type_id),
      place: workshop.place,
      register_count: workshop.attendees_count,
      register_max: workshop.maximum_number_of_attendee || 0,
      confirmation_start_date: workshop.confirmation_start_date,
      confirmation_end_date: workshop.confirmation_end_date,
      register_type: workshop.application_form_type && Number(workshop.application_form_type.id),
      organizer_name: workshop.organizer_name_th,
      organizer_position: workshop.organizer_position_th,
      published: workshop.published,
      published_at: workshop.published_at
        ? moment(workshop.published_at, "DD-MM-YYYY HH:mm:ss")
        : undefined,
      tags: workshop.tags.map((tag: any) => tag.tag),
    }
  }

  public async createWorkshop(workshop: IWorkshop | {}): Promise<IWorkshop> {
    const response = await this.apiService.post<IResultWorkshopResponse>(
      `backoffice/workshops`,
      workshop,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    const { result } = response.data.data
    return { id: result.id }
  }

  public async editWorkshop(id: number, workshop: any): Promise<any> {
    const response = await this.apiService.postFormData<any>(
      `backoffice/workshops/${id}`,
      workshop,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.workshop
  }

  public async deleteWorkshop(id: number): Promise<void> {
    const response = await this.apiService.delete<void>(`backoffice/workshops/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  /**
   * The result data should look like this
   * (I need to declare as any because of runtime resolving type)
   * {
   *    id: string
   *    blacklist: boolean
   *    registered_at: string
   *    status: "wait_approve" | "approve" | "cancel" | "attend" | "not_attended"
   *    is_attend: boolean
   *    ...otherAnswer (depends on form question)
   * }
   */
  public async getRegisterList(
    id: number,
    question: Array<IAttendeeDataTopic> = []
  ): Promise<Array<any>> {
    const response = await this.apiService.get<IListRegisterResponse>(
      `backoffice/workshops/${id}/attendees`,
      {},
      true
    )

    if (response.status !== ApiResponseStatus.Ok || !response.data.data.attendees) {
      return []
    }

    const attendees: Array<Record<string, any>> = response.data.data.attendees
    const convertedAttendeesArr = []

    for (const attendee of attendees) {
      const convertedAttendee: Record<string, any> = {}

      const formData = attendee.form_data

      // Add blacklist, registered date and id
      convertedAttendee["blacklist"] = attendee.is_blacklist
      convertedAttendee["registered_at"] = moment(formData.created_at).format("DD/MM/YYYY HH:mm")
      convertedAttendee["id"] = formData.id

      for (const answer of formData.answers) {
        // Get topic name
        const topicId = answer.form_question_id
        let [topic, filter] = question.reduce(
          (now, val) => {
            if (val.id === topicId) return [val.key, val.filters]
            return now
          },
          ["", ([] as unknown) as IFilterAttendeeDataTopic[] | undefined]
        )

        topic = topic.toLowerCase().split(" ").join("_")

        if (topic === "") continue

        // Get answer
        const isChoice = answer.choice !== null
        if (isChoice) {
          const answerChoiceId = answer.choice.form_question_choice_id
          const choiceValue = filter!.reduce((now, choice) => {
            if (choice.id === answerChoiceId) return choice.text
            return now
          }, "")

          convertedAttendee[topic] = choiceValue
        } else {
          // Get textinput value
          convertedAttendee[topic] = answer.text_input.text
        }
      }

      // Some error correction
      let status = attendee.attended_status
      if (status === "waiting_to_confim") status = "waiting_to_confirm"

      convertedAttendee["status"] = status
      convertedAttendee["cancel_reason"] = attendee.cancel_reason
      convertedAttendee["is_attend"] = convertedAttendee["status"] === "completed"
      convertedAttendeesArr.push(convertedAttendee)
    }

    return convertedAttendeesArr
  }

  public async deleteRegister(workshopId: number, attendeeId: number[]): Promise<Array<number>> {
    const response = await this.apiService.put<IDeleteMultipleRegisterResponse>(
      `backoffice/workshops/${workshopId}/attendees/all`,
      {
        applicationIds: attendeeId,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data.applicationIds
  }

  public async getRequestCancelList(id: number, filter: IRegisterFilter): Promise<IRegister[]> {
    const response = await this.apiService.get<IListRegisterResponse>(
      `backoffice/workshops/${id}/requestcancel`,
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data.attendees) {
      return []
    }
    return response.data.data.attendees.map((register: any) => {
      return {
        id: register.id,
      }
    })
  }

  public async BgetQuestionList(
    id: number,
    isExport?: boolean
  ): Promise<Array<IAttendeeDataTopic>> {
    const response = await this.getWorkshopApplicationForm(id)
    const question = response.questions.reduce<IAttendeeDataTopic[]>((now, val) => {
      let filters = undefined
      if (val.choices.length) {
        filters = val.choices.map(choiceVal => {
          return {
            id: choiceVal.id,
            text: choiceVal.description_th,
            value: choiceVal.description_th,
          }
        })
      }
      let question_name = val.question_th
      if (!isExport) question_name = question_name.split(" ")[0]

      let question_key = val.question_en.toLocaleLowerCase().split(" ").join("_")
      now.push({
        filters,
        fullTitle: val.question_th,
        title: question_name,
        dataIndex: question_key,
        key: question_key,
        id: val.id,
      })
      return now
    }, [])

    return question
  }

  public async deleteRequestCancel(id: number): Promise<void> {
    // const response = await this.apiService.delete<void>(
    //   `backoffice/workshops/requestcancel/${id}`,
    //   {},
    //   true
    // )
    // if (response.status !== ApiResponseStatus.Ok) {
    //   throw new Error(response.status + "")
    // }
  }

  public async addToBlackList(
    workshopId: number,
    attendeeId: number,
    reason: string
  ): Promise<any> {
    const response = await this.apiService.post<any>(
      `backoffice/workshops/${workshopId}/attendees/${attendeeId}/blacklist`,
      { reason },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.blacklist
  }

  public async getWorkshopApplicationForm(id: number): Promise<IForm> {
    const response = await this.apiService.get<IFormApiResponse>(`workshops/forms/${id}`)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async getWorkshopApplicationFormType(): Promise<any> {
    const response = await this.apiService.get<any>(`workshops/forms/`)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.applicationForm
  }

  public async applyWorkshop(workshopId: number, form: any): Promise<void> {
    const response = await this.apiService.post<any>(`workshops/${workshopId}/apply`, form, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async confirmWorkshop(workshopId: number, idList?: Array<number> | null): Promise<void> {
    const response = await this.apiService.post<any>(
      `backoffice/workshops/${workshopId}/confirm`,
      {
        idArray: idList,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getWorkshopAnnouncement(workshopId: number): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/workshops/${workshopId}/announcement`
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.data
  }

  public async updateWorkshopAnnouncement(workshopId: number, form: any): Promise<void> {
    const response = await this.apiService.postFormData<any>(
      `backoffice/workshops/${workshopId}/announcement`,
      form,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async closeWorkshop(workshopId: number): Promise<void> {
    const response = await this.apiService.postFormData<any>(
      `backoffice/workshops/${workshopId}/close`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async markAttend(workshopId: number, attendeeId: number): Promise<void> {
    const response = await this.apiService.postFormData<any>(
      `backoffice/workshops/${workshopId}/attendees/${attendeeId}/markattended`,
      {},
      true
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async exportData(workshopId: number, question: Array<IAttendeeDataTopic>): Promise<any> {
    const attendee = await this.getRegisterList(workshopId, question)

    let csvContent = ""

    // Append topic to csv
    csvContent += "วันเวลาที่ขอเข้าร่วม,"
    for (const each of question) {
      csvContent += `${each.fullTitle},`
    }
    csvContent += "สถานะ,\r\n"

    const questionKey = ["registered_at", ...question.map(val => val.key), "status"]

    // Add answer information for each attendee
    for (const eachAttendee of attendee) {
      for (const key of questionKey) {
        let value = eachAttendee[key] || "-"
        if (key === "status") {
          switch (eachAttendee[key]) {
            case "requested":
              value = "ส่งคำขอเข้าร่วม"
              break
            case "waiting_to_confirm":
              value = "รอยืนยันการเข้าร่วม"
              break
            case "confirmed":
              value = "ยืนยันแล้ว"
              break
            case "user_cancel":
              value = "ขอยกเลิก"
              break
            case "completed":
              value = "เข้าร่วมแล้ว"
              break
            case "not_attended":
              value = "ไม่ได้เข้าร่วม"
          }
        }
        csvContent += `${value},`
      }
      csvContent += "\r\n"
    }

    return csvContent
  }

  public async cancelWorkshop(id: number): Promise<void> {
    const response = await this.apiService.post<any>(`backoffice/workshops/${id}/cancel`, {}, true)

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async checkUserStatusInWorkshop(workshopId: number): Promise<void> {
    const response = await this.apiService.get<any>(`workshops/forms/${workshopId}/check`)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return
  }
}
