import React, { useEffect, useState } from "react"
import { Logo } from "components/base/Logo/Logo"
import { Menu } from "components/base/Menu/Menu"
import { MenuItem } from "components/base/Menu/MenuItem/MenuItem"
import { Avatar } from "components/base/Avatar/Avatar"
import { Bell } from "components/base/Bell/Bell"
import { Link } from "react-navi"
import { useTranslator } from "i18n/useTranslator"
import { Dropdown } from "components/base/Dropdown/Dropdown"
import { NavbarMenuUserMenu } from "./UserMenu/NavbarMenuUserMenu"

import styles from "./NavBar.module.less"
import { Hamburger } from "components/base/Hamburger/Hamburger"
import { useObserver } from "mobx-react"
import { NavbarViewModel } from "./NavbarViewModel"
import { NavbarMobileMenu } from "./MobileMenu/NavbarMobileMenu"
import { useContainer } from "services/containerProvider"
import { Button } from "components/base/Button/Button"
import { GlobalOutline } from "components/base/Icon/GlobalOutlined/GlobalOutlined"
import { Divider } from "antd"
import { LanguageMenu } from "./LanguageMenu/LanguageMenu"
import axios from "axios"
import { ApiService } from "services/apiService"


interface INavBarProps {
  selectedKey?: string
}

export const NavBar = ({ selectedKey }: INavBarProps): JSX.Element => {
  const t = useTranslator()
  const [viewModel] = useState(new NavbarViewModel(useContainer()))

  useEffect(() => {
    viewModel.init()
  }, [viewModel])

  return useObserver(() => (
    <>
      <Link href="/">
        <Logo />
      </Link>
      <div className={`${styles.alignRight} ${styles.rightContainer}`}>
        <div className={styles.menu}>
          <Menu theme="light" mode="horizontal" selectedKeys={selectedKey ? [selectedKey] : []}>
            {viewModel.isLoggedIn && (
              <MenuItem key="mindSpace+">
                {/* <Link href="/mindSpace+" onClick={viewModel.sendToMindSpacePlus}> Go to MindSpace+ </Link> */}
                <Button onClick={viewModel.sendToMindSpacePlus}> Go to MindSpace+ </Button>
              </MenuItem>
            )}
            <MenuItem key="profile">
              <Link href="/profile">Mind Journey</Link>
            </MenuItem>
            <MenuItem key="article">
              <Link href="/article">{t("navbar.article")}</Link>
            </MenuItem>
            <MenuItem key="assessment">
              <Link href="/assessment"> {t("navbar.assessment")}</Link>
            </MenuItem>
            <MenuItem key="workshop">
              <Link href="/workshop">{t("navbar.workshop")}</Link>
            </MenuItem>
            <MenuItem key="appointment">
              {viewModel.isAppointmentEnabled && (
                <Link href="/appointment"> {t("navbar.appointment")}</Link>
              )}
              {!viewModel.isAppointmentEnabled && (
                <a
                  href="https://wellness.chula.ac.th/?q=th/user/client_login"
                  target="_blank"
                  rel="noreferrer">
                  {t("navbar.appointment")}
                </a>
              )}
            </MenuItem>
          </Menu>
        </div>
        {/* <div className={styles.spaceAround}>
        <Bell />
      </div> */}
        <div className={styles.spaceAround}>
          {viewModel.isLoggedIn && viewModel.profileImage.length === 0 && (
            <Dropdown
              className={styles.avatar}
              trigger={["click"]}
              overlay={NavbarMenuUserMenu}
              placement="bottomRight">
              <Avatar />
            </Dropdown>
          )}
          {viewModel.isLoggedIn && viewModel.profileImage.length > 0 && (
            <Dropdown trigger={["click"]} overlay={NavbarMenuUserMenu} placement="bottomRight">
              <div>
                <img src={viewModel.profileImage} className={styles.profileImage} />{" "}
              </div>
            </Dropdown>
          )}
          {!viewModel.isLoggedIn && (
            <Link href="/login">
              <Button>{t("navbar.login")}</Button>
            </Link>
          )}
        </div>
        {/* <Divider type="vertical" />
        <div className={styles.spaceAround}>
          <Dropdown trigger={["click"]} overlay={LanguageMenu} placement="bottomRight">
            <span className={styles.language}>{viewModel.language}</span>
          </Dropdown>
        </div> */}
        <div className={`${styles.spaceAroundLast} ${styles.mobileMenu}`}>
          <Hamburger isOpen={viewModel.menuOpen} onClick={viewModel.toggleMenuOpen} />
          <NavbarMobileMenu selectedKey={selectedKey} isOpen={viewModel.menuOpen} />
        </div>
      </div>
    </>
  ))
}
